var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-responsive',{ref:"bannerParent",staticClass:"\n    yt-ratio-16x9\n    d-flex\n    flex-row\n    align-center\n    justify-center\n    text-center\n    my-2\n  "},[_c('youtube',{ref:"bannerYT",attrs:{"player-vars":{
      autoplay: 0,
      modestbranding: 1,
      loop: 0,
      controls: 1,
      playsinline: 0,
      rel: 0,
      enablejsapi: 0,
      frameborder: 0,
    },"player-height":_vm.playerHeight,"showinfo":"","player-width":"100%","video-id":_vm.videoId},on:{"ready":_vm.ready,"playing":_vm.playing}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }