<template>
  <v-expansion-panels
    v-if="rank && rank.data && rank.data.length > 0"
    focusable
    v-model="panel"
    multiple
  >
    <v-expansion-panel class="mb-1">
      <v-expansion-panel-header
        class="headline brown accent-4 white--text"
        expand-icon=""
      >
        <v-card-title tile class="pa-0 ma-0" dark>
          <div>
            {{ isHindi ? "टॉप प्रतिभागी" : "Top Participants" }}
            <div class="caption orange--text text--accent-1">
              {{
                isHindi
                  ? "इस परीक्षा टेस्ट के टॉप " +
                    rank.data.length +
                    " प्रतिभागी।"
                  : "Top " +
                    rank.data.length +
                    " participant of this exam test."
              }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-chip color="white orange--text">{{ rank.data.length }}</v-chip>
        </v-card-title>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list dense style="margin: 0 -1.5rem">
          <v-list-item
            @click="
              $router.push({
                name: 'profile',
                params: { id: item.user.uid },
              })
            "
            v-for="(item, i) in rank.data"
            :key="i"
          >
            <!-- <v-chip class="mr-1">{{ item.rank }}</v-chip> -->
            <v-avatar size="48" class="mr-1">
              <v-img :src="item.user.avatar"> </v-img>
            </v-avatar>
            <v-badge
              offset-x="22"
              offset-y="18"
              small
              :content="item.rank"
              overlap
              bordered
              color="green darken-2 white--text"
            ></v-badge>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.user.display_name }}
              </v-list-item-title>
              <div>
                <span class="caption grey--text mr-1">
                  <v-icon small class="mr-1">mdi-progress-check</v-icon>
                  {{ item.points_obtain }}/
                  {{ item.points_total }}
                </span>
                <span class="caption grey--text ml-1" v-if="item.elapsed">
                  <v-icon small class="mr-1">mdi-clock</v-icon>
                  {{ item.elapsed.toString().fancyTime() }}
                </span>
              </div>
            </v-list-item-content>
            <v-list-item-action-text>
              {{ item.accuracy }}
              <span style="">%</span>
              <v-icon class="ml-1">mdi-bullseye-arrow</v-icon>
            </v-list-item-action-text>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { axios } from "@/plugins/axios";
import localforage from "@/plugins/localforage";
import bus from "@/bus";

export default {
  name: "mocktest-ranks",
  props: ["rank"],
  data() {
    return {
      panel: [0],
    };
  },
  computed: {},
  methods: {
    fetchItems() {},
  },
  created() {
    this.fetchItems();
  },
};
</script>

<style>
</style>