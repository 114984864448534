import { AdMob, InterstitialAdPluginEvents } from "@capacitor-community/admob";

export async function interstitial(ctx = { adStatus: null }) {
  AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info) => {
    // Subscribe prepared interstitial
    ctx.adStatus = "loaded";
  });

  AdMob.addListener(InterstitialAdPluginEvents.FailedToLoad, (err) => {
    // Subscribe user rewarded
    ctx.adStatus = "failed";
  });

  AdMob.addListener(InterstitialAdPluginEvents.Showed, (err) => {
    // Subscribe user rewarded
    ctx.adStatus = "showed";
  });

  const options = {
    adId: "ca-app-pub-4686548797631822/1777190957",
    // isTesting: true
    npa: true,
  };
  await AdMob.prepareInterstitial(options);
  await AdMob.showInterstitial();
}
