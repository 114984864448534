<template>
  <v-responsive
    ref="bannerParent"
    class="
      yt-ratio-16x9
      d-flex
      flex-row
      align-center
      justify-center
      text-center
      my-2
    "
  >
    <youtube
      :player-vars="{
        autoplay: 0,
        modestbranding: 1,
        loop: 0,
        controls: 1,
        playsinline: 0,
        rel: 0,
        enablejsapi: 0,
        frameborder: 0,
      }"
      ref="bannerYT"
      :player-height="playerHeight"
      showinfo
      player-width="100%"
      :video-id="videoId"
      @ready="ready"
      @playing="playing"
    ></youtube>
  </v-responsive>
</template>

<script>
export default {
  name: "question-explanation-video",
  data() {
    return {
      player: null,
      refFound: false,
      playerHeight: 0,
    };
  },
  props: ["videoId"],
  methods: {
    ready(event) {
      this.player = event.target;
      console.log("video ready", this.videoId);
      this.$emit('ready', this.player);
    },
    playing(event) {
      // The player is playing a video.
      console.log("video playing", this.videoId);
    },
    change() {
      // when you change the value, the player will also change.
      // If you would like to change `playerVars`, please change it before you change `videoId`.
      // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
      // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
      this.videoId = "another video id";
    },
    stop() {
      this.player.stopVideo();
      console.log("video stoped", this.videoId);
    },
    pause() {
      this.player.pauseVideo();
      console.log("video pause", this.videoId);
    },
    handleBannerRef() {
      const bannerParentRef = this.$refs.bannerParent;
      const bannerRef = this.$refs.bannerYT;
      if (!bannerParentRef || !bannerRef) return;

      const parent = bannerParentRef.$el;

      const width = parent.offsetWidth > 0 ? parent.offsetWidth : 1500;
      const height = width / 1.778;

      this.playerHeight = height;

      if (bannerParentRef && bannerRef) {
        this.refFound = true;
      } else {
        this.refFound = false;
      }
    },
  },
  mounted() {
    let intrvl = setInterval(() => {
      this.handleBannerRef();
      if (this.refFound) {
        clearInterval(intrvl);
      }
    }, 1000);

    window.onWindowResize = () => {
      this.handleBannerRef();
    };
  },
};
</script>

<style >
.yt-ratio-16x9 {
  aspect-ratio: 16 / 8.7;
  background: url("/img/icons/android-chrome-192x192.png") rgb(255, 255, 255);
  background-size: fill;
  object-fit: fill;
  background-position: center;
  background-repeat: no-repeat;
}
</style>