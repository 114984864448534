<template>
  <v-row v-if="(adStatus == 'shown' || 'failed') && !loading">
    <v-col cols="12">
      <v-card>
        <v-card-title class="text-center justify-center">
          {{
            this.$store.getters.getCurrLocale == "hi"
              ? attempt.data.title_hi
              : attempt.data.title
          }}
        </v-card-title>
      </v-card>
    </v-col>

    <v-col v-if="rank && rank.total > 1" cols="12" md="6">
      <v-card dark>
        <v-img height="200px" :src="meta ? meta.backgrounds.card_1 : ''">
          <v-card-title>
            <!-- <v-icon class="mr-2">mdi-shield-star-outline</v-icon>
            {{ $lang("RANK") }} -->
            <v-avatar size="48" class="mr-2">
              <v-img :src="rank.user.avatar"></v-img>
            </v-avatar>
            <div>
              <div class="title">{{ rank.user.display_name }}</div>
            </div>
          </v-card-title>
          <v-card-text class="py-0 my-0">
            <span class="display-2">
              {{ rank.obtain }}
              <small style="vertical-align: super; margin-left: -0.2em">
                {{ rank.obtain.toString().ordinalSuffix() }}
              </small>
              <small>
                {{ $lang("RANK") }}
              </small>
            </span>
          </v-card-text>
          <v-card-text class="pt-3">
            <v-icon class="mr-1">mdi-shield-star-outline</v-icon>
            <span v-if="isHindi">
              आपने कुल
              {{ rank.total }}
              प्रतिभागियों में {{ rank.obtain }}
              <small style="vertical-align: super; margin-left: -0.15rem">
                {{ rank.obtain.toString().ordinalSuffix() }}
              </small>
              स्थान प्राप्त किया है।
            </span>
            <span v-else>
              You have obtained
              {{ rank.obtain }}
              <small style="vertical-align: super">
                {{ rank.obtain.toString().ordinalSuffix() }}
              </small>
              place in total {{ rank.total }} participants.
            </span>
          </v-card-text>
        </v-img>
      </v-card>
    </v-col>

    <v-col cols="12" md="6">
      <v-card dark>
        <v-img height="200px" :src="meta ? meta.backgrounds.card_2 : ''">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-icon class="mr-2">mdi-progress-check</v-icon>
                <span class="title">{{ $lang("SCORE") }}</span>
                <div>
                  <span class="display-1">{{ attempt.points_obtain }}</span>
                  <span class="headline">/</span>
                  <span class="headline">{{ attempt.points_total }}</span>
                </div>
              </v-col>
              <v-col cols="6" style="text-align: right">
                <v-icon class="mr-2">mdi-bullseye-arrow</v-icon>
                <span class="title">{{ $lang("ACCURACY") }}</span>
                <div class="display-1">{{ attempt.accuracy }}%</div>
              </v-col>
              <v-col cols="6">
                <v-icon class="mr-2">mdi-speedometer</v-icon>
                <span class="title">{{ $lang("SPEED") }}</span>
                <div class="display-1">
                  {{ attempt.questions_per_minute
                  }}<span class="title">Q / Min</span>
                </div>
              </v-col>
              <v-col cols="6" style="text-align: right">
                <v-icon class="mr-2">mdi-clock</v-icon>
                <span class="title">{{ $lang("DURATION") }}</span>
                <div class="title">
                  {{ attempt.elapsed.toString().fancyTime() }}/
                  {{ attempt.duration.toString().fancyTime() }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-img>
      </v-card>
    </v-col>

    <v-col v-if="rank && rank.total > 1" cols="12" md="12">
      <v-card dark>
        <v-img height="200px" :src="meta ? meta.backgrounds.card_3 : ''">
          <v-card-title>
            <v-icon class="mr-2">mdi-trophy-outline</v-icon>
            {{ isHindi ? "प्रतिभागी प्रतिशतता" : "Participant Percentile" }}
          </v-card-title>
          <v-card-text>
            <span class="display-2">
              {{ percentile }}<small style="vertical-align: super">%</small>
            </span>
          </v-card-text>
          <v-card-text class="pt-3">
            <span v-if="isHindi">
              {{ 100 - percentile }}
              <small style="">%</small>
              प्रतिभागी आप से बेहतर हैं.
            </span>
            <span v-else>
              {{ 100 - percentile }}
              <small style="">%</small> participants are doing better than you.
            </span>
          </v-card-text>
        </v-img>
      </v-card>
    </v-col>

    <v-col v-if="rank && rank.total > 1" cols="12" md="12">
      <mocktest-ranks :rank="rank" />
    </v-col>

    <v-col cols="12" v-if="attempt.id && attempt.data">
      <v-btn
        block
        class="primary"
        x-large
        @click="
          () => {
            showAnalysis = true;
          }
        "
      >
        {{ $lang("ShowAnalysis").ucWords() }}
      </v-btn>
      <CardDialog
        @closed="(val) => (showAnalysis = val)"
        :show="showAnalysis"
        :mocktest="attempt.data"
      />
    </v-col>

    <v-col cols="12" md="6">
      <v-card
        :color="
          attempt.questions_correct > attempt.questions_incorrect
            ? 'green'
            : 'red'
        "
        dark
      >
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
              <span class="title">{{ $lang("CORRECT") }}</span>
              <div class="display-1">{{ attempt.questions_correct }}</div>
            </v-col>
            <v-col cols="6" style="text-align: right">
              <v-icon class="mr-2">mdi-close-circle-outline</v-icon>
              <span class="title">{{ $lang("INCORRECT") }}</span>
              <div class="display-1">{{ attempt.questions_incorrect }}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card color="orange" dark>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-icon class="mr-2">mdi-format-list-checks</v-icon>
              <span class="title">{{ $lang("ATTEMPTED") }}</span>
              <div class="display-1">{{ attempt.questions_attempted }}</div>
            </v-col>
            <v-col cols="6" style="text-align: right">
              <v-icon class="mr-2">mdi-transit-skip</v-icon>
              <span class="title">{{ $lang("SKIPPED") }}</span>
              <div class="display-1">{{ attempt.questions_unattempted }}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <div v-else>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        {{ $lang("LOADING") }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-progress-linear
        color="orange accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import bus from "@/bus";
import { axios } from "@/plugins/axios";
import CardDialog from "./CardDialog";
import MocktestRanks from "../../modules/MocktestRanks";

import { Device } from "@capacitor/device";
import { RateApp } from "capacitor-rate-app";
import { interstitial } from "@/plugins/admob/interstitial";

export default {
  name: "attempt-result",
  components: {
    CardDialog,
    MocktestRanks,
  },
  data() {
    return {
      item: {},
      showAnalysis: null,
      loading: true,
      meta: null,
      rank: null,
      showAnswers: true,
      adStatus: null,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      mocktest: "app/exams/mocktest",
      attempt: "app/exams/attempt",
    }),
    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
      //this.$store.getters["app/exams/selectedTestLanguage"] == "hi";
    },
    percentile() {
      if (!this.rank) {
        return 0;
      }
      const percentile =
        ((this.rank.total - this.rank.obtain) / this.rank.total) * 100;
      return parseFloat(percentile.toFixed(2));
    },
  },
  methods: {
    fetchMeta() {
      return axios.get("/globals/meta/attempt/result/").then((res) => {
        this.meta = res.data.data;
      });
    },
    fetchAttempt() {
      return axios
        .get(`prep/attempts/attempted/${this.$route.params.id}`)
        .then((res) => {
          return this.$store.dispatch("app/exams/SaveAttempt", res.data.data);
        })
        .catch((err) => {
          // this test have not been attempted yet
        })
        .finally(() => {
          bus.$emit("hideWait");
          this.loading = false;
          this.check();
        });
    },
    fetchRanks() {
      return axios
        .get(
          `/prep/attempts/rank/${this.attempt.prep_mocktest_id}/${this.attempt.id}?page=1&perPage=5`
        )
        .then((res) => {
          this.rank = res.data;
          console.log(res.data);
        });
    },
    check() {
      if (!this.attempt || !this.attempt.id) {
        this.$router.replace({
          name: "exams-mocktest",
          params: {
            id: this.$route.params.id,
          },
        });
      } else {
        this.loading = false;
        this.fetchRanks();
      }
    },
  },
  watch: {
    attempt(val) {
      if (val) {
        this.loading = false;
      }
    },
  },

  created() {
    this.fetchMeta();
    if (
      this.attempt &&
      this.attempt.id &&
      this.attempt.id == this.$route.params.id
    ) {
      this.check();
    } else {
      this.fetchAttempt();
    }
  },
  async mounted() {
    this.info = await Device.getInfo();
    if (!this.adStatus != "showed") {
      if (this.info && this.info.platform != "web") {
        await interstitial(this);
      }
    }
    setTimeout(() => {
      if (this.info && this.info.platform != "web") {
        RateApp.requestReview();
      }
    }, 50000);
  },
};
</script>

